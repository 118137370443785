@charset "UTF-8";



/*==============================
タイトル以外の情報
==============================*/

#splash {
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
  .launch {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    // animation-name: fadein;
    // animation-duration: .15s;
    // animation-timing-function: ease;
    // animation-delay:.25s;
    // animation-iteration-count: 1;
    // animation-fill-mode: forwards;

    -webkit-animation: fadein .15s ease .25s 1 forwards;
    animation: fadein .15s ease .25s 1 forwards;
    .img{
      position: fixed;
      top: calc( 50% - 14px );
      left: calc( 50% - 63px );
      text-align: center;

      // animation-name: fadelogo;
      // animation-duration: .75s;
      // animation-timing-function: ease;
      // animation-delay:.25s;
      // animation-iteration-count: 1;
      // animation-fill-mode: forwards;

      .logo {
      -webkit-animation: fadelogo 3s ease .25s 1 forwards;
      animation: fadelogo 3s ease .25s 1 forwards;
        height: 28px;
        opacity: 0;
        width: 126px;
      }
    }
  }
}


@-webkit-keyframes fadein {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadelogo {
  0% {
    transform: translate(0, 30px);
    opacity: 0;
  }
  40% {
    transform: translateY(0);
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadelogo {
  0% {
    transform: translate(0, 30px);
    opacity: 0;
  }
  40% {
    transform: translateY(0);
  }
  55% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// @-webkit-keyframes zoom {
//   0% {
//     transform: scale(1.05);
//   }
//   100% {
//     transform: scale(1.0);
//   }
// }

// @keyframes zoom {
//   0% {
//     transform: scale(1.05);
//   }
//   100% {
//     transform: scale(1.0);
//   }
// }
// .wrap_slider .slider{
//     -webkit-animation: zoom 4.15s ease 1.25s 1 forwards;
//     animation: zoom 4.15s ease 1.25s 1 forwards;
// }
